import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_3/sub_1/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "slide-3-1-2__mobile"}}) {
      body
    }
    file(relativePath: { eq: "chapter_3/sub_1/mobile/3-1-2-bg__mobile.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    image: file(relativePath: { eq: "chapter_3/sub_1/mobile/3-1-2-photo__mobile.png"}) {
      childImageSharp {
        fixed(width: 1920) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
  },
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
